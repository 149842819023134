import React, { useState, useEffect } from "react"
import Toggle from "./themeToggle"
import { HiHeart } from "react-icons/hi"
import { FaBars } from "react-icons/fa"
import Favorites from "@components/Favorite/Favorites"
import { Link } from "gatsby"

export default () => {
  const [toggle, setToggle] = useState(false)

  function handleToggle() {
    document.body.classList.add("modal-open")
    setToggle(true)
  }

  function handleToggleClose() {
    setTimeout(() => {
      setToggle(false)
    }, 500)
  }

  return (
    <>
      <div class="navbar text-base-content mt-24 sm:mt-16 2xl:mt-20 z-10">
        <div class="container max-w-6xl 2xl:max-w-7xl m-auto z-10">
          <div class="px-2 sm:px-0 navbar-start flex">
            <Link class="w-10" to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 0 512 512"
              >
                <path
                  d="M-519.1 231.1c14.3-15 30-31.4 59.5-44.7-9-23.4-22.8-44.7-40.5-62.5-33 12.3-48.5 28.4-63.5 44.2-27 28.3-52.3 55.1-155.4 56.6-39.5.6-69.5 9.4-92.4 22.4v4.6c0 27.1 6 53.8 17.7 78.2 27-26.3 68.9-47.9 134.5-49 94-1.4 115.3-23.7 140.1-49.8z"
                  style={{ fill: "#3D5AFE" }}
                ></path>
                <path
                  d="M-579.3 153.3c14.5-15.3 30.4-32 61.1-45.4-24.1-18.8-52.7-31.2-82.9-36-34.9 48.9-95.1 58.7-116.1 60.6v-100c0-98.9-90.2-100.5-90.2-100.5h-4v290.9c24-11.5 53.9-19.1 92.2-19.8 93.9-1.3 115.3-23.8 139.9-49.8zM-453.2 206.9c-22.1 10.5-38.5 26.3-50.5 39-27 28.3-52.2 55-155.4 56.5-61.2 1-99.7 21.5-123.7 47.1 9.7 15.2 21.6 28.9 35.4 40.6 27.1-23.6 69.1-43.5 129.5-44.4 94.1-1.4 115.4-24 140.1-49.9 9.2-10.1 19.1-19.5 29.6-28.3.5-5.2.7-10.4.7-15.7 0-15.2-1.9-30.3-5.7-44.9z"
                  style={{ fill: "#3D5AFE" }}
                ></path>
                <path
                  d="M-617.8 367.3c-51.1.7-87.9 16.4-112.3 36.2 6.9 4.7 14.2 8.8 21.7 12.5v.3c5.3 2.5 10.7 4.7 16.3 6.6 20 7.4 41.2 11.1 62.5 11.1 16.2.3 32.4-1.8 47.9-6.4 60.9-16.8 108.8-63.8 126.7-124.4-2.6 2.6-5 5.2-7.5 7.8-26.6 28-52.1 54.7-155.3 56.3z"
                  style={{ fill: "#3D5AFE" }}
                ></path>
                <path
                  d="M396.9 256h-55.5c40.2 0 72.8-32.6 72.8-72.8V90.8c0-40.2-32.6-72.8-72.8-72.8H115.1c-40.2 0-72.8 32.6-72.8 72.8v230.7h.4c-.2 2.4-.4 4.9-.4 7.3V494h354.6c40.2 0 72.8-32.6 72.8-72.8v-92.3c0-40.3-32.6-72.9-72.8-72.9z"
                  style={{ fill: "#ffffff" }}
                ></path>
                <path
                  d="M42.3 321.5V90.8c0-40.2 32.6-72.8 72.8-72.8h226.3c40.2 0 72.8 32.6 72.8 72.8v92.3c0 40.2-32.6 72.8-72.8 72.8H193.8c-11.1 0-21.6 5.1-28.5 13.8l-30.2 38c-6.9 8.7-17.4 13.8-28.5 13.8H42.3z"
                  style={{ opacity: "0.85", fill: "#8F39FF" }}
                ></path>
                <path
                  d="M396.9 256H115.1c-40.2 0-72.8 32.6-72.8 72.8V494h354.6c40.2 0 72.8-32.6 72.8-72.8v-92.3c0-40.3-32.6-72.9-72.8-72.9z"
                  style={{ opacity: "0.85", fill: "#1574FF" }}
                ></path>
              </svg>
            </Link>
            <Link
              to="/"
              class="text-lg font-bold self-center"
              style={{
                fontWeight: "700",
                letterSpacing: "-1px",
                fontSize: "24px",
              }}
            >
              YourBrand
            </Link>
            {/* <button class="btn btn-outline btn-secondary btn-sm">Your Logo</button> */}
          </div>
          <div class="hidden px-2 mx-2 navbar-center lg:flex">
            <div class="flex items-stretch">
              <Link to="/" class="btn btn-ghost btn-sm rounded-btn">
                Home
              </Link>
              <Link to="/search" class="btn btn-ghost btn-sm rounded-btn">
                All Names
              </Link>
              <Link to="/faq" class="btn btn-ghost btn-sm rounded-btn">
                How it Works
              </Link>
              <Link to="/contact" class="btn btn-ghost btn-sm rounded-btn">
                Contact
              </Link>
            </div>
          </div>
          <div class="navbar-end text-right flex mr-4">
            <button class="btn btn-square btn-ghost mr-4">
              <Toggle />
            </button>
            <button class="btn btn-square btn-ghost">
              <label
                for="my-drawer-4"
                class="btn btn-primary"
                onClick={handleToggle}
              >
                <HiHeart className="hidden sm:block dark:text-gray-400 text-2xl cursor-pointer" />
                <FaBars className="sm:hidden dark:text-gray-400 text-2xl cursor-pointer" />
              </label>
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          zIndex: toggle ? "100" : "-10",
        }}
        class={`fixed top-0 inset-x-0 h-screen drawer drawer-end`}
      >
        <input id="my-drawer-4" type="checkbox" class="drawer-toggle"></input>
        <div class="flex flex-col items-center justify-center drawer-content">
          <div className="container h-screen relative overflow-hidden drawer drawer-end">
            <input
              id="my-drawer-4"
              type="checkbox"
              class="drawer-toggle"
            ></input>
            <div
              className="relative pt-6 pb-16 sm:pb-24"
              data-todo-x-data="Components.popover({ open: false, focus: true })"
              data-todo-x-init="init()"
              data-todo-at-keydown-escape="onEscape"
              data-todo-at-close-popover-group-window="onClosePopoverGroup"
            >
              {/* Main */}
            </div>
          </div>
        </div>
        <div class="drawer-side">
          <label
            onClick={handleToggleClose}
            for="my-drawer-4"
            class="drawer-overlay"
          ></label>
          <ul class="menu p-4 overflow-y-auto w-72 sm:w-80 bg-base-100 text-base-content">
            <div class="sm:hidden">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/search">All Names</Link>
              </li>
              <li>
                <Link to="/faq">How it Works</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </div>
            <Favorites />
          </ul>
        </div>
      </div>
    </>
  )
}
